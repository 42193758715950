import {useEffect, useState} from 'react';
import {renderToString} from 'react-dom/server';
import {useDispatch, useSelector} from 'react-redux';
import L from 'leaflet';
import {useMap} from 'react-leaflet/hooks';
import debounce from 'debounce';
import Form from 'react-bootstrap/Form';
import {
    setSelection as setUserSelection,
    setSubstation as setUserSubstation
} from '../redux/userSlice';
import {
    ArrowReturnLeft as IconClose,
    ArrowRepeat as IconRepeat,
    Cart2 as IconProject,
    Layers as IconLayers,
    Person as IconProfile,
    Search as IconSearch,
} from 'react-bootstrap-icons';
import {DataLayers, Profile, Project} from '.';
import {getCoordinatesForGeometry, getLatLng} from '../utils';
import {operatorService} from '../services';

export function Panel({
                          isLayersGeneralInit,
                          onFeatureSelect,
                          onFeaturesSelect,
                          project,
                          projectRemovePlot,
                          searchInput,
                          searchIsLoading,
                          setIsLayersGeneralInit,
                          setProject,
                          setSearchInput,
                          setSearchIsLoading
                      }) {
    const DEFAULT_VIEW_MODE = 'layers'; // project|profile|searchResults
    const dispatch = useDispatch();
    const map = useMap();

    const layersTypes = useSelector(state => state.layers.value.types);
    const userSelection = useSelector(state => state.user.value.selection);

    const [className, setClassName] = useState('');
    const [searchResultsCoordinates, setSearchResultsCoordinates] = useState(null);
    const [searchResultsInstallation, setSearchResultsInstallation] = useState([]);
    const [searchResultsMunicipality, setSearchResultsMunicipality] = useState([]);
    const [searchResultsPlot, setSearchResultsPlot] = useState([]);
    const [searchResultsProject, setSearchResultsProject] = useState([]);
    const [searchResultsSubstation, setSearchResultsSubstation] = useState([]);
    const [show, setShow] = useState(true);
    const [viewMode, setViewMode] = useState(DEFAULT_VIEW_MODE);

    useEffect(() => {
        const control = L.easyButton(renderToString(<IconSearch />), () => {
            setShow(true);
        }, 'Search')
            .setPosition('topleft')
            .addTo(map);

        // close on escape
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                setShow(false);
            }
        };
        window.addEventListener('keydown', handleEscape);

        return () => {
            map.removeControl(control);
            setShow(true);
            window.removeEventListener('keydown', handleEscape);
        };
    }, []);

    useEffect(() => {
        if (null !== project) {
            setViewMode('project');
        }

        return () => {
            setViewMode(DEFAULT_VIEW_MODE);
        }
    }, [project]);

    // reset results when searchInput gets set to false
    useEffect(() => {
        if (false === searchInput) {
            setSearchInput('');
            setSearchResultsCoordinates(null);
            setSearchResultsInstallation([]);
            setSearchResultsMunicipality([]);
            setSearchResultsPlot([]);
            setSearchResultsProject([]);
            setSearchResultsSubstation([]);
            setViewMode(DEFAULT_VIEW_MODE);
        }
    }, [searchInput]);

    useEffect(() => {
        setClassName(true === show ? 'showing' : 'hiding');

        return () => {
            setClassName('');
        };
    }, [show]);

    useEffect(() => {
        // stop event propagation to map for certain elements
        const elements = document.querySelectorAll('#panel input[type="text"], #panel label');
        elements.forEach(element => {
            L.DomEvent.disableClickPropagation(element);
            L.DomEvent.disableScrollPropagation(element);
        });
    }, [viewMode]);

    const handleSearch = event => {
        let input = event.target.value;

        // update search input value
        setSearchInput(input);

        // ignore if too short
        if (2 > input.length) {
            return;
        }

        input = input.trim();

        // check whether input is a GPS coordinate
        setSearchResultsCoordinates(null);
        const coordinates = getLatLng(input);
        if (null !== coordinates) {
            // show entered coordinates as a clickable result
            setSearchResultsCoordinates({
                '_id': input,
                'data': {
                    'latitude': coordinates[0],
                    'longitude': coordinates[1],
                },
                'display_name': input,
                'search_value': input,
                'type': 'Coordinates',
            });
            setViewMode('searchResults');

            // show nearby substations, if any
            undefined !== layersTypes.substation && operatorService.near(layersTypes.substation._id, coordinates)
                .then(items => {
                    setSearchResultsSubstation(
                        items.map(item => ({
                            '_id': item._id,
                            'code': item.metadata.id_PS,
                            'display_name': item.display_name,
                            'geometry': item.geometry,
                            'metadata': item.metadata,
                            'search_value': item.display_name,
                            'type': 'Substation',
                        }))
                    );
                });
        } else {
            debounce(() => {
                // search by substation
                // setSearchResultsSubstation([]);
                undefined !== layersTypes.substation && operatorService
                    .autocomplete(layersTypes.substation._id, ['display_name', 'metadata.Nom PS'], input, 5)
                    .then(items => {
                        setSearchResultsSubstation(
                            items.map(item => {
                                let searchValue = item.display_name;
                                if (true === 'Nom PS' in item.metadata) {
                                    searchValue = `${item.metadata['Nom PS']} ${searchValue}`;
                                }
                                return {
                                    '_id': item._id,
                                    'display_name': item.display_name,
                                    'geometry': item.geometry,
                                    'metadata': item.metadata,
                                    'search_value': searchValue,
                                    'type': 'Substation',
                                };
                            })
                        );
                        setViewMode('searchResults');
                    });
                // search by municipalities
                // setSearchResultsMunicipality([]);
                undefined !== layersTypes.municipality && operatorService
                    .autocomplete(
                        layersTypes.municipality._id,
                        ['display_name', 'metadata.code', 'metadata.codesPostaux'],
                        input,
                        5
                    )
                    .then(items => {
                        const results = items.map(item => {
                            let codeInsee = '';
                            if (undefined !== item.metadata.code) {
                                codeInsee = ` ${item.metadata.code}`;
                            }

                            return {
                                '_id': item._id,
                                'data': {
                                    'coordinates': getCoordinatesForGeometry(item.geometry),
                                },
                                'display_name': item.display_name,
                                'geometry': item.geometry,
                                'search_value': `${item.display_name}${codeInsee}`,
                                'type': 'Municipality',
                            };
                        });
                        setSearchResultsMunicipality(results);
                        setViewMode('searchResults');
                    });
                // search by plot ID
                // setSearchResultsPlot([]);
                undefined !== layersTypes.plot && operatorService
                    .autocomplete(layersTypes.plot._id,['display_name'], input, 5)
                    .then(items => {
                        setSearchResultsPlot(
                            items.map(item => ({
                                '_id': item._id,
                                'display_name': item.display_name,
                                'geometry': item.geometry,
                                'metadata': item.metadata,
                                'search_value': item.display_name,
                                'type': 'Plot',
                            }))
                        );
                        setViewMode('searchResults');
                    });
                // search by project
                // setSearchResultsProject([]);
                undefined !== layersTypes.project && operatorService
                    .autocomplete(layersTypes.project._id, ['display_name'], input, 5)
                    .then(items => {
                        setSearchResultsProject(
                            items.map(item => ({
                                '_id': item._id,
                                'data': {
                                    'geometry': item.geometry,
                                },
                                'display_name': item.display_name,
                                'metadata': item.metadata,
                                'search_value': item.display_name,
                                'type': 'Project',
                            }))
                        );
                        setViewMode('searchResults');
                    });
                // search by JBox/IECharge
                // setSearchResultsInstallation([]);
                undefined !== layersTypes.installation && operatorService
                    .autocomplete(layersTypes.installation._id, ['display_name'], input, 5)
                    .then(items => {
                        setSearchResultsInstallation(
                            items.map(item => ({
                                '_id': item._id,
                                'data': {
                                    'geometry': item.geometry,
                                },
                                'display_name': item.display_name,
                                'metadata': item.metadata,
                                'search_value': item.display_name,
                                'type': 'Installation',
                            }))
                        );
                        setViewMode('searchResults');
                    });
            }, 300)();
        }
    };

    const selectResult = newSelection => {
        setSearchIsLoading(true);
        setSearchInput(newSelection.search_value);
        setViewMode('layers');
        dispatch(setUserSelection(newSelection));
        dispatch(setUserSubstation('Substation' === newSelection.type ? newSelection : null));
    };

    const render = () => {
        return (
            <>
                {'profile' !== viewMode && <div className="input-group content mt-3">
                    <Form.Control
                        className="form-control-lg"
                        onChange={handleSearch}
                        placeholder="Search here..."
                        type="text"
                        value={searchInput}
                    />
                    {null !== userSelection && (
                        <span
                            className="input-group-text nav-item px-2"
                            onClick={() => {
                                dispatch(setUserSelection({
                                    ...userSelection,
                                }));
                            }}
                            title="Refresh"
                        ><IconRepeat size={20}/></span>
                    )}
                </div>}
                {renderViewMode()}
            </>
        );
    };

    const renderViewMode = () => {
        switch (viewMode) {
            case 'profile':
                return (
                    <div className="content pt-2">
                        <Profile />
                    </div>
                );
            case 'project':
                return (
                    <div className="content">
                        <Project
                            project={project}
                            removePlot={projectRemovePlot}
                            setProject={setProject}
                        />
                    </div>
                );
            case 'searchIsLoading':
                return (
                    <div className="content">
                        <ul>
                            <li className="fst-italic no-results p-2">Loading...</li>
                        </ul>
                    </div>
                );
            case 'searchResults':
                return (
                    <div className="content search-results">
                        {null === searchResultsCoordinates &&
                            0 === searchResultsSubstation.length &&
                            0 === searchResultsMunicipality.length &&
                            0 === searchResultsPlot.length &&
                            0 === searchResultsProject.length && (
                                <div className="fst-italic text-center me-3">No results</div>
                            )}
                        {null !== searchResultsCoordinates && (
                            <>
                                <div className="fst-italic">Coordinates</div>
                                <ul>
                                    <li onClick={() => selectResult(searchResultsCoordinates)} role="button">
                                        Latitude: {searchResultsCoordinates.data.latitude}, Longitude:{' '}
                                        {searchResultsCoordinates.data.longitude}
                                    </li>
                                </ul>
                            </>
                        )}
                        {0 < searchResultsSubstation.length && (
                            <>
                                <div className="fst-italic">Substations</div>
                                <ul>
                                    {searchResultsSubstation.map((result, key) => (
                                        <li key={key} onClick={() => selectResult(result)} role="button">
                                            {result.search_value.toUpperCase()}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {0 < searchResultsMunicipality.length && (
                            <>
                                <div className="fst-italic">Municipalities</div>
                                <ul>
                                    {searchResultsMunicipality.map((result, key) => (
                                        <li key={key} onClick={() => selectResult(result)} role="button">
                                            {result.search_value.toUpperCase()}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {0 < searchResultsPlot.length && (
                            <>
                                <div className="fst-italic">Plots</div>
                                <ul>
                                    {searchResultsPlot.map((result, key) => (
                                        <li key={key} onClick={() => selectResult(result)} role="button">
                                            {result.search_value.toUpperCase()}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {0 < searchResultsProject.length && (
                            <>
                                <div className="fst-italic">Projects</div>
                                <ul>
                                    {searchResultsProject.map((result, key) => (
                                        <li key={key} onClick={() => selectResult(result)} role="button">
                                            {result.search_value.toUpperCase()}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {0 < searchResultsInstallation.length && (
                            <>
                                <div className="fst-italic">JBox & IECharge</div>
                                <ul>
                                    {searchResultsInstallation.map((result, key) => (
                                        <li key={key} onClick={() => selectResult(result)} role="button">
                                            {result.search_value.toUpperCase()}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                );
        }
    };

    return (
        <div className={`offcanvas offcanvas-start w-25 ${className}`} id="panel" role="dialog">
            <div className="offcanvas-body p-0">
                {render()}
                <DataLayers
                    isFiltersShow={'layers' === viewMode}
                    isGeneral={true}
                    isLayersGeneralInit={isLayersGeneralInit}
                    isSearchLoading={searchIsLoading}
                    onFeatureSelect={onFeatureSelect}
                    setIsLayersGeneralInit={setIsLayersGeneralInit}
                />
                <DataLayers
                    isFiltersShow={'layers' === viewMode}
                    isGeneral={false}
                    isLayersGeneralInit={isLayersGeneralInit}
                    isSearchLoading={searchIsLoading}
                    onFeatureSelect={onFeatureSelect}
                    onFeaturesSelect={onFeaturesSelect}
                    setIsLayersGeneralInit={setIsLayersGeneralInit}
                />
            </div>
            <ul className="nav nav-fill my-2">
                <li className={`nav-item${'layers' === viewMode ? ' active' : ''}`}>
                    <IconLayers
                        aria-label="Layers"
                        onClick={() => setViewMode('layers')}
                        size={32}
                        title="Layers"
                    />
                </li>
                <li className={`nav-item${'project' === viewMode ? ' active' : ''}`}>
                    <IconProject
                        aria-label="Project"
                        onClick={() => setViewMode('project')}
                        size={32}
                        title="Project"
                    />
                </li>
                <li className={`nav-item${'profile' === viewMode ? ' active' : ''}`}>
                    <IconProfile
                        aria-label="Profile"
                        onClick={() => setViewMode('profile')}
                        size={32}
                        title="Profile"
                        type="button"
                    />
                </li>
                <li className="nav-item">
                    <IconClose
                        aria-label="Close"
                        onClick={() => setShow(false)}
                        size={32}
                        title="Close"
                        type="button"
                    />
                </li>
            </ul>
        </div>
    );
}
