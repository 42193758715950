import {useEffect, useId, useState} from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import ReactSlider from 'react-slider';

// for every Atlas layer
export function FilterLineForm({defaultValue, field, updateValue, isDisabled}) {
    const id = useId();
    const isFloat = false === Number.isInteger(field.step);

    const [value, setValue] = useState(field.value || defaultValue || 0);

    // init value
    useEffect(() => {
        // parse float values to support decimals
        if ('NUM' === field.type && '' !== field.value && true === isFloat) {
            setValue([field.value[0]*10, field.value[1]*10]);
        }

        return () => {
            setValue(field.value || defaultValue || 0);
        };
    }, []);

    const switchOnChange = localValue => {
        if (false === localValue) {
            localValue = '';
        }
        setValue(localValue);
        updateValue(localValue);
    };

    switch (field.type) {
        case 'CHOICE':
            return [...field.choices].sort().map((choice, key) =>
                <Form.Check
                    checked={true === value.includes(choice)}
                    disabled={true === isDisabled}
                    id={`${field.field}-${choice}-${id}`}
                    key={key}
                    label={choice}
                    onChange={() => {
                        // value is an array of values
                        let localValue;
                        if (true === value.includes(choice)) { // remove
                            localValue = value.filter(v => choice !== v);
                        } else { // add
                            localValue = [
                                ...value,
                                choice,
                            ];
                        }

                        setValue(localValue);
                        updateValue(localValue);
                    }}
                    type="switch"
                />
            );
        case 'FLAG':
            return (
                <Form.Switch
                    checked={true === value}
                    disabled={true === isDisabled}
                    label={<span onClick={() => switchOnChange(!value)}>{field.name}</span>}
                    onChange={event => switchOnChange(event.currentTarget.checked)}
                />
            );

        case 'NUM':
            // ReactSlider does not support decimals, hence multiply and divide accordingly
            let defaultValue;
            let labelValueFrom;
            let labelValueTo;
            let max;
            let min;
            let step;
            if (true === isFloat) {
                defaultValue = [field.min*10, field.max*10];
                labelValueFrom = (value[0]/10).toFixed(1);
                labelValueTo = (value[1]/10).toFixed(1);
                max = field.max*10;
                min = field.min*10;
                step = field.step*10;
            } else {
                defaultValue = value;
                labelValueFrom = value[0];
                labelValueTo = value[1];
                max = field.max;
                min = field.min;
                step = field.step;
            }

            return (
                <InputGroup className="mb-3" size="sm">
                    <Form.Label className="mb-0" size="sm">
                        {field.name}<span className="fw-bold ps-1">{labelValueFrom} → {labelValueTo}</span>
                    </Form.Label>
                    <ReactSlider /*  */
                        ariaLabel={['Min', 'Max']}
                        defaultValue={defaultValue}
                        disabled={true === isDisabled}
                        onChange={values => {
                            // values is an array of with min and max
                            setValue(values);
                            if (true === isFloat) {
                                updateValue([values[0]/10, values[1]/10]);
                            } else {
                                updateValue(values);
                            }
                        }}
                        max={max}
                        min={min}
                        minDistance={step}
                        value={value}
                    />
                </InputGroup>
            );
    }
}
