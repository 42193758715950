import { MapContainer } from 'react-leaflet';
import { Map } from '../components';
import 'leaflet/dist/leaflet.css';

/**
 * The home page which renders the map container and includes the map component.
 */
function HomeContent() {
    return (
        <MapContainer scrollWheelZoom={true}>
            <Map />
        </MapContainer>
    );
}

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const Home = () => {
    return (
        <HomeContent />
    );
};
