import React from 'react';
import {renderToString} from 'react-dom/server';
import L from 'leaflet';
import {JsonEditor} from 'json-edit-react';
import {alertService} from '../services';
import {availableModes, markerIconInstallation, MODE_PROSPECTION} from '.';

export const DEFAULT_NON_ATLAS_FILTER_VALUES = {
    'condition': '',
    'key': '',
    'keyNextLevel': '',
    'value': '',
};

export const DEFAULT_NON_ATLAS_FILTER = {
    0: DEFAULT_NON_ATLAS_FILTER_VALUES,
};

export const METADATA_POPUP_OPTIONS = {
    'maxHeight': 250,
    'maxWidth': 250,
};

export function addDynamicStyles(combinedStyles, dynamicStyles, featureProperties) {
    let styles = {};

    // loop through all dynamic styles, key by key, and attempt to match a feature property
    Object.keys(dynamicStyles).forEach(key => {
        const featureValue = featureProperties?.[key];
        // we have a matching value
        if (undefined !== featureValue) {
            let condition = 'eq';
            let dynamicStyleValues = [];
            let dynamicStyleKeys = [];
            let style = {};

            // dynamic styles with conditions, that have numbers as keys
            if ('values' in dynamicStyles[key]) {
                if ('condition' in dynamicStyles[key]) { // there should always be a condition, better be safe
                    condition = dynamicStyles[key]['condition'];
                }
                dynamicStyleValues = dynamicStyles[key]['values'];
                // order number/float values based on condition to check them in the right order
                dynamicStyleKeys = Object.keys(dynamicStyleValues).map(value => parseFloat(value)).sort();
                if (condition === 'gte') {
                    dynamicStyleKeys.sort((a, b) => b - a); // highest value needs to be first for >= comparison
                }
            // dynamic styles without condition
            } else {
                dynamicStyleValues = dynamicStyles[key];
                dynamicStyleKeys = Object.keys(dynamicStyleValues);
            }

            // loop through given keys (i.e. values) from dynamicStyles and attempt to match the condition
            dynamicStyleLoop:
            for (const dynamicStyleKey of dynamicStyleKeys) {
                const dynamicStyle = dynamicStyleValues[dynamicStyleKey];
                switch (condition) {
                    case 'eq':
                        if (featureValue == dynamicStyleKey) {
                            style = dynamicStyle;
                            break dynamicStyleLoop; // we got our style, let's get out of here
                        }
                        break;
                    case 'gte':
                        if (featureValue >= dynamicStyleKey) {
                            // console.log('we have a match', featureValue, dynamicStyleKey, dynamicStyle);
                            style = dynamicStyle;
                            break dynamicStyleLoop;
                        }
                        break;
                    case 'lte':
                        if (featureValue <= dynamicStyleKey) {
                            style = dynamicStyle;
                            break dynamicStyleLoop;
                        }
                        break;
                    case 'incl':
                        if (true === featureValue.includes(dynamicStyleKey)) {
                            style = dynamicStyle;
                            break dynamicStyleLoop;
                        }
                        break;
                }
            }

            styles = {
                ...styles,
                ...style,
            };
        }
    });

    // only set when we have a value
    if (undefined !== styles.color) combinedStyles.color = styles.color;
    if (undefined !== styles.fillColor) combinedStyles.fillColor = styles.fillColor;
    if (undefined !== styles.fillOpacity) combinedStyles.fillOpacity = styles.fillOpacity;
    if (undefined !== styles.opacity) combinedStyles.opacity = styles.opacity;
    if (undefined !== styles.radius) combinedStyles.radius = styles.radius;
    if (undefined !== styles.weight) combinedStyles.weight = styles.weight;

    return combinedStyles;
}

export function bindMetadataPopupActions() {
    const toggleCollection = (actionCollapse, isCollapsed = null) => {
        isCollapsed ??= actionCollapse.classList.contains('jer-rotate-90');
        const collectionName = actionCollapse.parentElement;
        const collectionHeaderRow = collectionName.parentElement;
        const collectionInner = collectionHeaderRow.nextSibling;
        const contentBrackets = collectionName.nextSibling.nextSibling;
        actionCollapse.classList.toggle('jer-rotate-90');
        collectionInner.style['overflow-y'] = true === isCollapsed ? 'visible' : 'clip';
        collectionInner.style['max-height'] = true === isCollapsed ? 'initial' : 0;
        ['jer-hidden', 'jer-visible'].map(cssClass => contentBrackets.classList.toggle(cssClass));
    };

    let levelCount = 1; // first level is already excluded through following query selector
    document.querySelectorAll('.jer-editor-container .jer-collection-inner .jer-collapse-icon').forEach(actionCollapse => {
        // collapse levels higher than levelCount
        if (1 < levelCount++) {
            toggleCollection(actionCollapse, false);
        }

        // bring back click on toggle
        actionCollapse.addEventListener('click', e => toggleCollection(actionCollapse));
    });
}

export function copyDeep(object) {
    return JSON.parse(JSON.stringify(object));
}

export function copyToClipboard(value, onSuccess = null) {
    navigator.clipboard
        .writeText(value)
        .then(() => {
            alertService.success(`<strong>${value}</strong> copied to clipboard.`);
            if (onSuccess !== null) {
                onSuccess();
            }
        })
        .catch(() => {
            alertService.error('Something went wrong with copying data to clipboard');
        });
}

export function createMarkerPopup(marker, setUserSelection, setSearchInput) {
    const coordinates = marker.getLatLng();
    const coordinatesString = `${coordinates.lat},${coordinates.lng}`;

    const elementUl = document.createElement('ul');
    elementUl.setAttribute('class', 'list-unstyled');

    // search nearby
    const elementLiSearch = document.createElement('li');
    const elementLiSearchLink = document.createElement('a');
    elementLiSearchLink.setAttribute('href', '');
    elementLiSearchLink.textContent = 'Search around here';
    elementLiSearchLink.onclick = e => {
        e.preventDefault();
        setUserSelection({
            'data': {
                'latitude': coordinates.lat,
                'longitude': coordinates.lng,
            },
            'id': coordinatesString,
            'display_name': coordinatesString,
            'type': 'Coordinates',
        });
        setSearchInput(coordinatesString);
        marker.closePopup();
    };
    elementLiSearch.appendChild(elementLiSearchLink);
    elementUl.appendChild(elementLiSearch);

    // launch Google Maps
    const elementLiGmaps = document.createElement('li');
    const elementLiGmapsLink = document.createElement('a');
    elementLiGmapsLink.setAttribute('href', `https://www.google.com/maps/search/?api=1&query=${coordinatesString}`);
    elementLiGmapsLink.setAttribute('target', '_blank');
    elementLiGmapsLink.textContent = 'Open in Google Maps';
    elementLiGmaps.appendChild(elementLiGmapsLink);
    elementUl.appendChild(elementLiGmaps);

    return elementUl;
}

export function createMetadataPopup(userMode, layerType, title, data, whitelistedFields = []) {
    return L.popup(METADATA_POPUP_OPTIONS)
        .setContent(getMetadataPopup(userMode, layerType, title, data, whitelistedFields))
        .on('add', bindMetadataPopupActions);
}

export function filter(formValues, data) {
    let filteredData = data;
    Object.keys(formValues).forEach(localFormValueKey => {
        const formValue = formValues[localFormValueKey];
        // identify the right column, either a first or a second level property key
        const hasSecondLevel = '' !== formValue.keyNextLevel;
        filteredData = filteredData.filter(data => {
            // hit the right data based on the selected level
            if (true === hasSecondLevel) {
                const nextLevelProperties = data.properties[formValue.key];
                return null !== nextLevelProperties &&
                    true === isObject(nextLevelProperties) &&
                    0 < Object.keys(nextLevelProperties).filter(nextLevelProperty => {
                        return filterCheck(formValue.condition, nextLevelProperties[nextLevelProperty], formValue.value);
                    }).length;
            } else {
                return filterCheck(formValue.condition, data.properties[formValue.key], formValue.value);
            }
        });
    });

    return filteredData;
}

export function filterCheck(condition, data, value) {
    if (null === data) {
        return false;
    }

    // e.g. codesPostaux is a one-element array
    if (true === Array.isArray(data)) {
        data = data[0];
    }

    switch (condition) {
        default:
        case 'eq':
            if ('string' === typeof data) {
                return data.toLowerCase() === value.toLowerCase();
            }
            return data == value; // it is important to not type-compare since int, float, string can be compared
        case 'gte':
            return data >= value;
        case 'lte':
            return data <= value;
        case 'incl':
            if ('string' === typeof data) {
                return true === data.toLowerCase().includes(value.toLowerCase());
            } else if (true === Array.isArray(data)) {
                return true === data.includes(value);
            } else { // should never happen, data is either a string or an array
                return false;
            }
        case 'not':
            return data != value;
    }
}

export function getAvailableModes(country) {
    const countryAvailableModes = {};
    for (const [key, value] of Object.entries(availableModes)) {
        if (country in value.activeLayers) {
            countryAvailableModes[key] = value;
        }
    }

    return countryAvailableModes;
}

// returns the average latitude and longitude for an array of coordinates
export function getCenter(coordinates) {
    const [y, x] = coordinates.reduce((sum, coord) => {
        sum[0] += parseFloat(coord[0]);
        sum[1] += parseFloat(coord[1]);
        return sum;
    }, [0, 0]);

    return [y / coordinates.length, x / coordinates.length];
}

// get array of coordinates from geometry, the one and only for polygons or the one with the most elements for MultiPolygon
export function getCoordinatesForGeometry(geometry) {
    if (geometry.type === 'MultiPolygon') {
        let maxElementsCount = -1;
        let maxElementsKey;
        for (let i = 0; i < geometry.coordinates.length; i++) {
            const elementsCount = geometry.coordinates[i]?.[0].length;
            if (maxElementsCount < elementsCount) {
                maxElementsCount = elementsCount;
                maxElementsKey = i;
            }
        }
        return geometry.coordinates[maxElementsKey]?.[0];
    }

    return geometry.coordinates[0];
}

export function getGeoJsonFromElements(elements) {
    const geoJson = [];
    elements.forEach(element => {
        if (undefined !== element) {
            geoJson.push({
                'display_name': element.display_name,
                'geometry': element.geometry,
                'id': element._id,
                'properties': element.metadata,
                'type': 'Feature',
            });
        }
    });

    return geoJson;
}

export function getLatLng(string, separator = ',') {
    let coordinates = string.split(separator).map(coordinate => {
        return parseFloat(coordinate.trim());
    });

    // didn't find comma-separated coordinates, try semicolon
    if (coordinates.length !== 2 || coordinates.includes(NaN)) {
        if (separator !== ';') {
            return getLatLng(string, ';');
        }

        return null;
    }

    return coordinates;
}

// take customization settings from backend and fill in missing default values
export function getLayerSettings(layer) {
    return {
        'country': layer?.properties?.country || 'FR',
        'display_fields': layer?.properties?.display_fields || [],
        'scope': layer?.properties?.scope || 'search',
        'search_fields': layer?.properties?.search_fields || [],
        'style': {
            'active': layer?.customization?.active,
            'default': {
                'color': layer?.customization?.default?.color || '#E3256B', // border color
                'fillColor': layer?.customization?.default?.fillColor || '#FD7F4F', // shape color
                'fillOpacity': layer?.customization?.default?.fillOpacity || 1, // shape opacity
                'opacity': layer?.customization?.default?.opacity || 1, // border opacity
                'radius': layer?.customization?.default?.radius || 5,
                'weight': layer?.customization?.default?.weight || 1, // border width
            },
            'dynamic': layer?.customization?.dynamic,
        },
        'type': layer?.properties?.type || 'default',
    };
}

export function getMetadataPopup(userMode, layerType, title, data, whitelistedFields = []) {
    // clean data
    if (0 < whitelistedFields.length) {
        data = Object.fromEntries(Object.entries(data).filter(([key, value]) => whitelistedFields.includes(key)));
    }

    // tweak data
    const dataSubstations = {};
    if ('plot' === layerType && true === Array.isArray(data.substations)) {
        data.substations.forEach(substation => {
            const substationFormatted = {};
            Object.keys(substation).forEach(key => {
                if (true === key.includes('FLAG_')) {
                    substationFormatted[key.slice(5)] = substation[key];
                } else if (true === key.includes('NUM_')) {
                    substationFormatted[key.slice(4)] = substation[key];
                // remove connection points for a given mode and a given key/value
                } else if (MODE_PROSPECTION === userMode && 'connection_points' === key) {
                    const connectionPoints = [];
                    // iterate through connection points
                    for (const [connectionPointKey, connectionPoint] of Object.entries(substation[key])) {
                        if ('distance_HTA_line' in connectionPoint && 80 > connectionPoint['distance_HTA_line']) {
                            connectionPoints.push(connectionPoint);
                        }
                    }
                    substationFormatted[key] = connectionPoints;
                } else if ('code' !== key) {
                    // make 'code' the new key
                    substationFormatted[key] = substation[key];
                }
            });

            dataSubstations[substation.code] = substationFormatted;
        });
    }

    if (false === isObjectEmpty(dataSubstations)) {
        data = {
            ...data,
            'substations': dataSubstations,
        };
    }

    return renderToString(
        <div>
            <h5>{title}</h5>
            <JsonEditor
                collapse={false}
                enableClipboard={false}
                data={data}
                indent={1}
                keySort={true}
                restrictAdd={true}
                restrictEdit={true}
                restrictDelete={true}
                rootName={''}
                customNodeDefinitions={[
                    {
                        'condition': key => {
                            // custom color for all booleans
                            if ('boolean' === typeof key.value) {
                                return true;
                            }
                            // custom color for all values matching those listed, e.g. ok, ko
                            if ('string' === typeof key.value && ['ok', 'ko', 'nok'].includes(key.value.toLowerCase())) {
                                return true;
                            }

                            return false;
                        },
                        'element': getMetadataPopupJsonEditorNodeColor,
                    }, {
                        'condition': key => {
                            // color substation label green when rating > 0
                            if ('substations' === key.path[0] &&
                                2 === key.level &&
                                0 < key.value.rating) {
                                return true;
                            }

                            return false;
                        },
                        'wrapperElement': getMetadataPopupJsonEditorNodeWrapper,
                        'wrapperProps': {
                            'className': 'jer-key-color-green',
                        }
                    }, {
                        'condition': key => {
                            // color substation label red when rating = 0
                            if ('substations' === key.path[0] &&
                                2 === key.level &&
                                0 === key.value.rating) {
                                return true;
                            }

                            return false;
                        },
                        'wrapperElement': getMetadataPopupJsonEditorNodeWrapper,
                        'wrapperProps': {
                            'className': 'jer-key-color-red',
                        }
                    }
                ]}
            />
        </div>
    );
}

// wraps JsonEditor values in classes to apply custom colors
class getMetadataPopupJsonEditorNodeColor extends React.Component {
    render() {
        const type = typeof this.props.value;
        const value = this.props.value.toString();
        let className = 'jer-value-color-';

        switch (type) {
            case 'boolean':
                className += true === this.props.value ? 'green' : 'red';
                break;
            case 'string':
                className += 'ok' === this.props.value.toLowerCase() ? 'green' : 'red';
        }

        if ('jer-value-color-' !== className) {
            return <span className={className}>{value}</span>;
        }

        return value;
    }
}

class getMetadataPopupJsonEditorNodeWrapper extends React.Component {
    render() {
        return <span className={this.props.customNodeProps.className}>{this.props.children}</span>;
    }
}

// get dynamic style for feature
export function getStyle(layer, feature, filteredSubstationCodes = []) {
    // apply default styles
    let style = {...layer.style.default};

    // legacy for plot layers, @TODO: remove once backend sends aggregated max rating
    if ('plot' === layer.type && undefined !== layer.style?.dynamic?.NUM_rating) {
        // we need this custom code since we have ratings on a substation-level, get the highest substation rating
        const substations = 'substations' in feature.properties ? feature.properties['substations'] : [];
        let rating = 0;

        // get the biggest rating of provided substations
        substations.forEach(substation => {
            if ('NUM_rating' in substation &&
                substation['NUM_rating'] > rating && (
                    0 === filteredSubstationCodes.length || // ignore this substation if not in filteredSubstationCodes
                    true === filteredSubstationCodes.includes(substation.code))) {
                rating = substation['NUM_rating'];
            }
        });

        return addDynamicStyles(style, layer.style.dynamic, {'NUM_rating': rating});
    }

    // apply dynamic styles
    if (undefined !== layer.style.dynamic) {
        return addDynamicStyles(style, layer.style.dynamic, feature.properties);
    }

    // return default styles
    return style;
}

export function inverseCoordinates(coordinates) {
    return coordinates.map(coordinate => [coordinate[1], coordinate[0]]);
}

export function isObject(value) {
    return 'object' === typeof value;
}

export function isObjectEmpty(object) {
    return 0 === Object.keys(object).length;
}

export function isObjectEqual(object1, object2) {
    return JSON.stringify(object1) === JSON.stringify(object2);
}

export function mapInteractionDisable(map) {
    // create a div, placed above all the rest, to block popups etc.
    const blockInteractionPane = document.getElementById('block-interaction');
    blockInteractionPane.insertBefore(L.DomUtil.create('div'), blockInteractionPane.children[0]);
    // // disable interactions
    // map.boxZoom.disable();
    // map.doubleClickZoom.disable();
    // map.dragging.disable();
    // map.keyboard.disable();
    // map.scrollWheelZoom.disable();
    // map.touchZoom.disable();
    // if (map.tap) map.tap.disable();
    map.getContainer().style.cursor = 'default';
}

export function mapInteractionEnable(map) {
    // remove the blocking div
    document.getElementById('block-interaction').replaceChildren()
    // // enable interactions
    // map.boxZoom.enable();
    // map.doubleClickZoom.enable();
    // map.dragging.enable();
    // map.keyboard.enable();
    // map.scrollWheelZoom.enable();
    // map.touchZoom.enable();
    // if (map.tap) map.tap.enable();
    map.getContainer().style.cursor = 'grab';
}

// layerData is already contained in layer, but we cannot always modify layer.data, i.e. when applying filter() on load
export function renderLayer(layer, layerGroup, onFeatureSelect, filteredSubstationCodes = []) {
    const layerRef = L.geoJSON(layer.data, {
        'onEachFeature': (feature, leafletLayer) => {
            leafletLayer.on({
                'click': event => onFeatureSelect(layer, feature, event, layerRef),
            });
        },
        // style points
        'pointToLayer': (feature, center) => {
            // show default marker for unscoped layers with Point data
            if (undefined === layer.scope) {
                return new L.Marker(center);
            }

            // show custom marker if requested
            if ('installation' === layer.type && true !== layer?.customization?.useCustomMarker) {
                const style = getStyle(layer, feature);
                return new L.Marker(center, {
                    'icon': markerIconInstallation(style.fillColor),
                });
            }

            return L.circleMarker(center, getStyle(layer, feature));
        },
        // style polygons
        'style': feature => getStyle(layer, feature, filteredSubstationCodes),
    }).addTo(layerGroup);

    return layerRef;
}

export function resetFeatureStyle(layerRef) {
    // we don't always have a reference, e.g. for react native's municipality polygons
    if (null !== layerRef) {
        // reset styles of all features
        layerRef.resetStyle();
    }
}

// remove all existing layers, this also switches the eye icon to hidden
export function resetMap(map, layers, layerGroup) {
    // remove each layer reference, without updateDataLayer we won't have a clean map
    layers.forEach(layer => {
        layer.layerRef.remove();
    });
    // clear layers from layer group
    layerGroup.clearLayers();
    // remove entire layer group from map
    map.removeLayer(layerGroup);
}
