import {createSlice} from '@reduxjs/toolkit'

export const countrySlice = createSlice({
    'name': 'country',
    'initialState': {
        'value': {
            'current': null,
            'list': [],
        },
    },
    'reducers': {
        'setCurrent': (state, action) => {
            state.value.current = action.payload;
        },
        'setList': (state, action) => {
            state.value.list = action.payload;
        },
    },
});

export const {setCurrent, setList} = countrySlice.actions;

export default countrySlice.reducer;
