import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {setCurrent as setCountry} from '../redux/countrySlice';
import {setModes as setUserModes, setSelection as setUserSelection, setSubstation as setUserSubstation} from '../redux/userSlice';
import {renderToString} from 'react-dom/server';
import {useMap} from 'react-leaflet/hooks';
import L from 'leaflet';
import 'leaflet-easybutton/src/easy-button';
import {GlobeAmericas as IconGlobe} from 'react-bootstrap-icons';
import {Form, Modal} from 'react-bootstrap';
import {MODE_DEFAULT, setUserProfile} from '../utils';

// see https://rona.sh/centroid/
export const COUNTRY_CENTROIDS = {
    'FI': {
        'lat': 64.25166553094144,
        'lng': 26.1908710320857,
        'zoom': 5,
    },
    'FR': {
        'lat': 46.50593635598827,
        'lng': 2.570466278050072,
        'zoom': 6,
    },
    'IT': {
        'lat': 42.70562377620437,
        'lng': 12.202818710437269,
        'zoom': 6,
    },
    'JP': {
        'lat': 37.41985172439148,
        'lng': 137.60498728587166,
        'zoom': 6,
    },
    'SE': {
        'lat': 62.38119411102905,
        'lng': 16.277225035586387,
        'zoom': 5,
    },
    'TX': {
        'lat': 31,
        'lng': -100,
        'zoom': 6,
    },
};

export function CountrySelector({ setSearchInput }) {
    const dispatch = useDispatch();
    const map = useMap();

    const country = useSelector(state => state.country.value.current);
    const countryList = useSelector(state => state.country.value.list);
    const userLayers = useSelector(state => state.user.value.layers);
    const userModes = useSelector(state => state.user.value.modes);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const control = L.easyButton(renderToString(<IconGlobe />), () => {
            setShowModal(true);
        }, 'Select Country')
            .setPosition('topright')
            .addTo(map);

        return () => {
            map.removeControl(control);
            setShowModal(false);
        };
    }, []);

    return (
        <Modal backdrop={true} centered={true} onHide={() => setShowModal(false)} show={showModal}>
            <Modal.Header closeButton>
                <Modal.Title>Country Selection</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Select aria-label="Available Countries" onChange={event => {
                    setSearchInput(false);
                    const selectedCountry = event.target.value;
                    dispatch(setCountry(selectedCountry));

                    let localUserModes = {...userModes};
                    // init mode for selected country, if not set
                    if (false === selectedCountry in localUserModes) {
                        localUserModes[selectedCountry] = MODE_DEFAULT;
                    }
                    dispatch(setUserModes(localUserModes));

                    setUserProfile({
                        'country': selectedCountry,
                        'layers': userLayers,
                        'modes': localUserModes,
                    });

                    dispatch(setUserSelection(null));
                    dispatch(setUserSubstation(null));

                    setShowModal(false);
                    if (selectedCountry in COUNTRY_CENTROIDS) {
                        const center = [COUNTRY_CENTROIDS[selectedCountry].lat, COUNTRY_CENTROIDS[selectedCountry].lng];
                        map.flyTo(center, COUNTRY_CENTROIDS[selectedCountry].zoom);
                    }
                }} value={country}>
                    {countryList.map((country, key) => <option key={key} value={country}>{country}</option>)}
                </Form.Select>
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    );
}
